<template>
  <div class="quest-list">
    <div class="quest-list-wrap">
      <div class="quest-item" v-for="item,index in list" :key="index" @click="$emit('confirm', item)">
        <img v-if="index < 3" :src="imgs[index]" class="img" />
        <span>{{ index > 2 ? index + 1 + '、' : '' }}{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import hot1 from '@/assets/hot_1.png'
import hot2 from '@/assets/hot_2.png'
import hot3 from '@/assets/hot_3.png'

export default {
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      imgs: [hot1,
      hot2,
      hot3]
    }
  }
}
</script>

<style scoped lang="scss">
.quest-list {
  padding: 10px;
  overflow: hidden;
  height: 100%;
  &-wrap {
    padding: 10px 10px;
    overflow: auto;
    height: 90%;
    border: 1px solid #ffffff30;
    border-radius: 10px;
  }
}
.quest-title {
  font-size: 16px;
  color: #999;
  margin-bottom: 5px;
}
.quest-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-bottom: 6px;
  cursor: pointer;
  .img {
    width: 14px;
    margin-right: 2px;
  }
}
</style>