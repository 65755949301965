import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data,
  })
}

export function getToken() {
  return request({
    url: '/api/test/integrate',
    method: 'get'
  })
}

export function getData(data) {
  return request({
    url: '/api/api/test',
    method: 'post',
    data,
  })
}

export function uploadAudio(data) {
  return request({
    url: '/api/nls/recognizer',
    method: 'post',
    data
  })
}