<template>
  <div id="index" :class="[hasMap && 'hasMap', !texts.length && 'notext', isPhone && 'phone']" ref="appRef">
    <!-- <div v-if="isFirstEntry" class="bg first">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="first-wrap">
        <div class="title">测试平台</div>
        <div class="search">
          <input :value="searchValue" class="search_input" placeholder="请输入" @input="onInputChange" @keyup.enter="getSearchData">
          <span class="search_btn" @click="getSearchData">搜索</span>
        </div>
      </div>
    </div> -->
    <div class="bg">
      <div class="bg-title"></div>
      <div v-if="nodata" class="nodata">
        <img :src="nodataImg" />
        <div class="text">{{ nodataText }}</div>
      </div>
      <dv-loading class="loading" v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="host-body-title">
          <!-- <dv-decoration-8 style="width:calc(50% - 200px);height:50px;" />
          <dv-decoration-7 style="width:400px;height:30px;margin: 0 auto;">  
            <div class="search">
              <input :value="searchValue" class="search_input" placeholder="请输入" @input="onInputChange" @keyup.enter="getSearchData">
              <span class="search_btn" @click="getSearchData">搜索</span>
            </div>
          </dv-decoration-7>
          <dv-decoration-8 :reverse="true" style="width:calc(50% - 200px);height:50px;" /> -->

          <div class="search">
            <img class="search_audio" :src="audioImg" @click="onAudio" />
            <input :value="searchValue" class="search_input" placeholder="请输入" @input="onInputChange" @keyup.enter="getSearchData">
            <span class="search_btn" @click="getSearchData">搜索</span>
            <Audio ref="audioRef" @upload="onUpload" :isPhone="isPhone"></Audio>
          </div>
        </div>
        <div v-if="texts.length && !nodata" class="header">
          <!-- <dv-border-box-12> -->
          <div class="header">
            <div class="header-info">
              <div :class="['header-info-item', `header-info-item-${index}`]" v-for="item, index in texts.slice(0, 3)" :key="index">
                <div class="title">{{ item.chart_title }}{{ item.unit ? `(${item.unit})` : '' }}</div>
                <div class="content">
                  <span class="value">{{ (item?.data?.value && typeof item?.data?.value === 'number' && item.data.value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || item.data.value }}</span>
                  <!-- <span class="unit">{{ item.unit }}</span> -->
                </div>
              </div>
              <!-- <div class="dv">
                <dv-decoration-10 style="width:90%;height:5px;margin:0 auto;" />
              </div> -->
            </div>
          </div>
          <!-- </dv-border-box-12> -->
        </div>
        <div v-else class="header-place" style="height: 5vh;"></div>
        <div v-if="!nodata" class="list">
          <div v-for="item, index in list" :class="['item', item.container && 'container', 'w-' + item.w, 'h-' + item.h]"  :key="index">
            <template v-if="item.container" >
              <div v-for="child, childIndex in (item.data || [])" :class="['item', 'w-' + child.w, 'h-' + child.h]" :key="getStartIndex(list, index) + childIndex">
                <ChartGroup :data="charts[getStartIndex(list, index) + childIndex]" :index="getStartIndex(list, index)" :isMap="child.map" :isPhone="isPhone" @listClick="onListClick"></ChartGroup>
              </div>
            </template>
            <template v-else>
              <ChartGroup :data="charts[getStartIndex(list, index)]" :index="getStartIndex(list, index)" :isMap="item.map" :isPhone="isPhone" @listClick="onListClick"></ChartGroup>
            </template>
          </div>
        </div>
        <!-- <div v-for="item, index in charts" :class="['item', 'w-' + item.w, 'h-' + item.h]" :key="index">
          <dv-border-box-12>
            <Map v-if="item.type === 'heatMap'" :id="'bottomLeftChart' + index" :data="item"></Map>
            <div v-else-if="item.type === 'table'" class="table" >
              <div class="title">{{ item.title }}</div>
              <el-table
              :data="item.data.data"
               style="width: 100%"
              >
              <el-table-column
                v-for="col, index in item.data.column"
                :prop="col.prop"
                :label="col.label"
                :key="col.prop + index"
              >
              </el-table-column>
            </el-table>
            </div>
            <Echart
              v-else-if="item.type !== 'stereoscopicPie'"
              :options="{ 
                title: { text: item.title, left: 'center', top: item.data.series?.length > 1 ? '10%' : '5%', textStyle: { color: '#fff' } }, 
                grid: { left: '15%', top: '20%', right: '10%', bottom: '15%' }, 
                xAxis: { name: item.xname || '', data: item.data.categories, axisLabel: { interval: 0, overflow: 'truncate', ellipsis: '...', rotate: -25 } }, 
                yAxis: { name: item.yname ? item.yname + '/' + item.unit : '' }, 
                series: item.data.series,
                legend: { data: item.data.series, show: item.data.series?.length > 1, top: '3%' },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
              }"
              :id="'bottomLeftChart' + index"
              height="100%"
              width="100%"
              :type="item.type"
            ></Echart>
            <Echart
              v-else
              :options="{ 
                title: { text: item.title, left: '2%', top: '4%', textStyle: { color: '#fff' } }, 
                grid: { left: '15%', top: '30%', right: '10%', bottom: '10%' },
                series: { type: 'pie', data: item.data },
                legend: { data: item.data, show: item.data?.length > 1, bottom: '1%' },
                tooltip: {
                  trigger: 'item'
                }
              }"
              :id="'bottomLeftChart' + index"
              height="100%"
              width="100%"
            ></Echart>
          </dv-border-box-12>
        </div> -->
      </div>
    </div>
    <div id="tokenInfo" v-html="tokenInfo"></div>
    <div class="bg-img"></div>
    <div class="audio-loading" v-if="audioLoading">
      <div class="loading-wrap">
        <div v-loading="audioLoading"></div>
        <div class="loading-text">语音识别中...</div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutData from '../utils/layoutData'
import MockData from '../utils/mock'
import Theme from '../common/echart/theme.json'
import { getData, login, getToken, uploadAudio } from '@/api'
import nodataImg from '@/assets/nodata.png'
import audioImg from '@/assets/audio.png'
import ChartGroup from './ChartGroup.vue'
import lightImg from '@/assets/light.png'
import Audio from './Audio.vue'

console.log('MockData', MockData)
const count = 10
console.log(`output-> LayoutData['' + count + '_MAP']`, LayoutData[`LAYOUT_` + count + '_MAP'])

export default {
  mixins: [ ],
  data() {
    return {
      nodataImg,
      audioImg,
      isFirstEntry: true,
      loading: false,
      nodata: false,
      nodataText: '',
      decorationColor: ['#568aea', '#000000'],
      list: [],
      texts: [],
      charts: [],
      options: {},
      token: '',
      searchValue: '',
      tokenInfo: '',
      hasMap: false,
      audioLoading: false,
      isPhone: window.innerWidth < 1080
    }
  },
  components: {
    ChartGroup, Audio
  },
  created() {
    this.initViewport()
  },
  mounted() {
    this.init() 
    this.getBase64Image(lightImg)
    // this.cancelLoading()
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    init() {
      if (this.loading) return
      this.loading = true
      login({ username: 'admin', password: 'admin123', rememberMe: false})
        .then(res => {
          console.log(1)
          getToken().then(tokenInfo => {
            const tokenDom = new DOMParser().parseFromString(tokenInfo, 'text/html')
            console.log(`output-> tokenDom.querySelectorAll('#token')`, tokenDom.querySelector('#token').children[2].value)
            this.token = tokenDom.querySelector('#token').children[2].value
            this.tokenInfo = ''
            this.loading = false
          })
          console.log(`output-> res`, res)
        }).catch(err => {
          console.error(`获取token失败`, err)
          this.loading = false
        })
    },
    getSearchData() {
      this.nodata = false
      if (!this.searchValue || this.loading) return
      this.isFirstEntry = false
      this.loading = true
      getData({ token: this.token, text: this.searchValue })
        .then(res => {
          console.log('getData', res.data)
          this.dealData(res.data)
        })
        .catch((err) => {
          console.log('this.nodata', err)
          // this.dealData(MockData)
          this.loading = false
          this.nodata = true
          if (err.msg) {
            this.nodataText = err.msg
            return
          }

          if (err.data?.answerDto) {
            this.nodataText = '发生错误'
            return
          }

          this.nodataText = '暂无数据'
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 
    onInputChange(e) {
      this.searchValue = e.target.value
    },
    dealData(data) {
      //
      if (!data.answerDto.chart_list.length) {
        this.nodata = true
        this.nodataText = '暂未找到数据'
        return
      }

      // 
      let texts = [];
      let charts = [];
      (data.answerDto.chart_list || []).map(res => {
        res.type === 'text' && res.chart_title ? texts.push(res) : charts.push(res)
      })
      this.texts = texts
      console.log('%chome.vue line:267  texts', 'color: #007acc;',  texts);
      if (charts[charts.length - 1].type === 'text' && !charts[charts.length - 1].chart_title) {
        charts[charts.length - 1].type = 'list'
        charts[charts.length - 1].chart_title = '个性化推荐'
      }
      if (charts.length > 10 && charts[charts.length - 1].type === 'list') {
        const listData = charts.splice(charts.length - 1, 1)
        charts.splice(9, 0, listData[0])
      }
      charts = charts.length > 10 ? charts.splice(0, count) : charts
      const hasMapIndex = charts.findIndex(res => res.type === 'heatMap')
      this.hasMap = !!~hasMapIndex
       console.log(`output-> list`, `LAYOUT_` + charts.length + (this.hasMap ? '_MAP' : ''))
      const list = LayoutData[`LAYOUT_` + charts.length + (this.hasMap && !this.isPhone ? '_MAP' : '')]
      this.list = list
      console.log(`output-> list`, charts, list, this.hasMap)
      if (this.hasMap) {
        if (!this.isPhone) {
          const mapIndex = this.getMapIndex()
          console.log(`output-> delItem hasMapIndex`, hasMapIndex)
          let delItem = charts.splice(hasMapIndex, 1)
          console.log(`output-> delItem 1`, hasMapIndex, delItem[0], charts)
          charts.sort(function(a,b){
            if(a.priority < b.priority){
                return -1;
            }
            if(a.priority > b.priority){
              return 1;
            }
            return 0;
          })
          charts.splice(mapIndex, 0, delItem[0])
          console.log(`output-> delItem 2`, delItem[0], charts)
        } else {
          let delItem = charts.splice(hasMapIndex, 1)
          charts.splice(0, 0, delItem[0])
        }
      }

      this.charts = charts.map((res) => {
        if (res.type === 'line' && res.data.series.length) {
          res.data.series = res.data.series.map((serie, serieIndex) => {
            console.log(Theme.color[serieIndex], Theme.color[serieIndex] + '10')
            serie.smooth = false;
            serie.areaStyle = {
              color: {
                x: 0,
								y: 1,
                colorStops: [
									{
										offset: 0,
										color: Theme.color[serieIndex] // 0% 处的颜色
									},
									{
										offset: 1,
										color: Theme.color[serieIndex] + '10' // 
									}
								],
              },
            }
            serie.symbolSize = 6
            serie.lineStyle = {
              width: 4,
              // color: {
              //   x: 0,
							// 	y: 1,
              //   colorStops: [
							// 		{
							// 			offset: 0,
							// 			color: Theme.color[serieIndex] + '50' // 0% 处的颜色
							// 		},
							// 		{
							// 			offset: 1,
							// 			color: Theme.color[serieIndex] + '90' // 
							// 		}
							// 	],
              // },
            }
            // serie.markLine = {
            //   silent: true,
            //   symbol: ["none", "none"],
            //   lineStyle: {
            //       color: "#ccc",
            //   },
            //   animation: false, //关闭动画
            //   label: {
            //     show:true,
            //     formatter: function() {
            //       return '预测之后'
            //     },
            //   },
            //   data: [
            //       {
            //           xAxis: 2,//在x轴12格处设置一条参考线
            //       },
            //   ],
            // }
            return serie
          })
          res.data.series.push({
            showSymbol: false,
            name: "",
            type: "lines",
            polyline: true,
            smooth: false,
            coordinateSystem: "cartesian2d",
            zlevel: 1,
            effect: {
              show: true,
              smooth: true,
              period: 6,
              symbolSize: 6,
            },
            lineStyle: {
              color: "#fff",
              width: 1,
              opacity: 0,
              curveness: 0,
              cap: "round",
            },
            data: this.getCoorData(res.data.categories, res.data.series[0].data),
          })
        }
        if (res.type === 'bar' || res.type === 'stackedBar' || res.type === 'lineBar') {
          res.data.series = res.data.series.map((serie, serieIndex) => {
            serie.barMaxWidth = 18;
            serie.itemStyle = {
              normal: {
                barBorderRadius:[10, 10, 0, 0],
                color: {
                  x: 0,
                  y: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color:  Theme.color[serieIndex] + '10'// 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: Theme.color[serieIndex] // 
                    }
                  ],
                }
              },
            }
            if (res.type === 'bar' || res.type === 'lineBar') {
              serie.itemStyle.normal.color = function(params) { 
                //首先定义一个数组 
                const colorList = [Theme.color[0], Theme.color[1]]; 
                let color = ''
                if(params.dataIndex % 2 == 0){
                  color = colorList[0]
                }else{
                  color = colorList[1]
                }
                return {
                  x: 0,
                  y: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color:  color + '10'// 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: color // 
                    }
                  ],
                }
              }
            }
            return serie
          })
        }
        // if (res.xname === '月' && Array.isArray(res.data?.categories)) {
        //   res.data.categories = res.data.categories.map(res => {
        //     if (res.indexOf('-')) {
        //       res = res.split('-')[1]
        //     }
        //     return res
        //   })
        // }
        // return { ...res, ...list[index] }
        return res
      })
      console.log(`output-> list`, charts, list, this.hasMap)
    },
    getCookie(cookieName) {
      const strCookie = document.cookie
      const cookieList = strCookie.split(';')
      
      for(let i = 0; i < cookieList.length; i++) {
        const arr = cookieList[i].split('=')
        if (cookieName === arr[0].trim()) {
          return arr[1]
        }
      }
      
      return ''
    },
    getStartIndex(list, curIndex) {
      let startIndex = 0
      if (curIndex === startIndex) return startIndex
      for (let index = 0; index < curIndex; index++) {
        startIndex += (list[index].data?.length || 1)
      }
      console.log(`output-> startIndex`, startIndex)
      return startIndex
    },
    // 获取布局中地图所在一维数组中的下标 将charts中的map数据移动到对应下标中
    getMapIndex() {
      let mapIndex = 0
      const list = this.list
      for (let index = 0; index < list.length; index++) {
        const cur = list[index]
        // 如果没有地图标识 跳过
        if (!cur.map) {
          mapIndex += (cur.data?.length - 1 || 1)
          continue
        }

        // 如果是容器 地图肯定在data里面
        if (cur.container) {
          for (let j = 0; j < cur.data.length; j++) {
            mapIndex += (j + 1)
            if (cur.data[j].map) {
              return mapIndex
            }
            continue
          }
        }

        mapIndex += index
        return mapIndex
      }
    },
    getCoorData(xData, yData) {
      //多线段（polyline=true），如图左侧连续一段：
        let datacoords = [
          {
            coords: [],
          },
        ];
      for (var i = 0; i < xData.length; i++) {
        datacoords[0].coords.push([xData[i], yData[i]]);
      }
      return datacoords
    },
    onListClick(item) {
      this.searchValue = item
      this.getSearchData()
    },
    getBase64Image: src => {
      return new Promise(resolve => {
          const img = new Image()
          img.crossOrigin = ''
          img.src = src
          img.onload = function () {
              const canvas = document.createElement('canvas')
              canvas.width = img.width
              canvas.height = img.height
              const ctx = canvas.getContext('2d')
              ctx?.drawImage(img, 0, 0, img.width, img.height)
              const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
              const dataURL = canvas.toDataURL('image/' + ext)
              resolve(dataURL)
          }
      })
    },
    async onUpload(file) {
      console.log(`output-> file`, file)
      this.audioLoading = true
      try {
        const result = await uploadAudio(file)
        console.log(`output-> result`, result)
        if (result.data) {
          this.searchValue = result.data
          // this.getSearchData()
        } else {
          this.$message.warning('未识别到您说的内容')
        }
      } catch(err) {
        this.$message.error('识别失败')
      } finally {
        this.audioLoading = false
      }
    },
    onAudio() {
      this.$refs.audioRef.handleStart()
    },
    initViewport() {
      const height = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight
      const metaEl = document.querySelector("#viewportMeta");
      const content = "height=" + height + ",width=device-width,initial-scale=1.0,user-scalable=no";
      metaEl.setAttribute('name', 'viewport');
      metaEl.setAttribute('content', content);
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'douyuFont';
  src: url(../assets/font/douyuFont-2.otf);
}
.bg {
  width: 100vw;
  height: 100vh;
  // background-image: url("../assets/pageBg.png");
  
  position: relative;
  z-index: 2;
  // background: #070f3b70;
  &-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/earth4.gif");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.4;
    z-index: 1;
  }
  &-title {
    height: 7vh;
    background: url('../assets/title.png') no-repeat center center;
    background-size: 100% auto;
    color: #fff;
    text-align: center;
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.host-body {
  width: 100%;
  height: 100%;
  &-title {
    font-size: 2vw;
    color: #fff;
    text-align: center;
    padding: 0 10px;
    // background-image: linear-gradient(rgba(6, 30, 93, 0.5), rgba(6, 30, 93, 0.4));
    display: flex;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    height: 6vh;
  }
}

.header {
  height: 9vh;
  // display: flex;
  // justify-content: center;
  // align-content: center;
  // box-sizing: border-box;
  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 5px;
    flex-wrap: wrap;
    height: 100%;
    &-item {
      flex: 1;
      color: #fff;
      width: 100%;
      height: 100%;
      // background-color: rgba(6,30,93,.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 20px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        // background: #fff;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
      .title {
        text-align: center;
        width: 100%;
        font-size: 12px;
      }
      .content {
        text-align: center;
        width: 100%;
      }
      .value {
        font-size: 2vw;
        letter-spacing: 2px;
        font-weight: bold;
        font-family: douyuFont;
      }
      .unit {
        padding-left: 6px;
        font-size: 12px;
      }
      &-0 {
         .value {
          color: #2ec7c9;
        }
      }
      &-1 {
        .value {
          color: #b6a2de;
        }
      }
      &-2 {
        .value {
          color: #5ab1ef;
        }
      }
      &-3 {
        .value {
          color: #ffb980;
        }
      }
      &-4 {
        .value {
          color: #8d98b3;
        }
      }
      &-5 {
        .value {
          color: #8e5cf0;
        }
      }
      &-6 {
        .value {
          color: #97b552;
        }
      }
    }
    .dv {
      width: 100%;
    }
  }
}

.search {
  background: rgba(6, 30, 93, 0.5);
  border: 1px solid #3b5f95;
  border-radius: 999px;
  position: relative;
  width: 50vw;
  margin: 0 auto;
  &_audio {
    position: absolute;
    left: 0.5vw;
    width: 2vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &_input {
    background: none;
    color: #fff;
    border: none;
    height: 4.6vh;
    width: 100%;
    box-sizing: border-box;
    padding-right: 6.1vw;
    padding-left: 3vw;
  }
  &_btn {
    font-size: 14px;
    height: 4.6vh;
    line-height: 4.6vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 6vw;
    cursor: pointer;
    color: #4c90de;
    background: #152c50;
    box-shadow: inset 0px 0px 20px 6px rgba(0, 145, 255, 1);
    overflow: hidden;
    border-radius: 0 999px 999px 0;
  }
}

.item {
  // background: rgba(255, 255, 255, 0.5);
  float: left;
  // border: 1px solid #fff;
  color: #fff;
  // background-color: rgba(6, 30, 93, 0.5);
}

// 一格的宽高 一共10列 6行
$w: 10vw;
$h: 13vh;

// 生成w-1 到 w-10
@for $i from 1 through 10 {
  .w-#{$i} {
    width: $w * $i
  }
}
// 生成h-1 到 h-6
@for $i from 1 through 6 {
  .h-#{$i} {
    height: $h * $i
  }
}

#tokenInfo {
  visibility: hidden;
}
.nodata {
  position: fixed;
  left: 50%;
  top: 50%;
  color: #ccc;
  font-size: 1.8vw;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    width: 10vw;
    margin-bottom: 10px;
  }
  .text {
    line-height: 1.3;
  }
}
.first {
  &-wrap {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: 3vw;
    color: #fff;
    margin-bottom: 2vw;
    text-align: center;
  }
  .search {
    width: 40vw;
    height: 6vh;
  }
  .search_btn {
    height: 6vh;
    line-height: 6vh;
    padding: 0;
    width: 8vw;
    text-align: center;
    color: #fff;
  }
  .search_input {
    height: 6vh;
    line-height: 6vh;
    padding-right: 8vw;
  }
}

.loading {
  color: #fff;
}

.audio-loading {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  .loading-wrap {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    border-radius: 6px;
    overflow: hidden;
    background: #00000060;
    > div {
      height: 100px;
      ::v-deep .el-loading-mask {
        background: none;
      }
    }
  }
  .loading-text {
    color: #fff;
    // color: #4d86d6;
    text-align: center;
    font-size: 16px;
  }
}

.phone {
  .search {
    width: 90vw;
    &_audio {
      left: 2vw;
      width: 6vw;
    }
    &_input {
      padding-left: 10vw;
      padding-right: 20.1vw;
    }
    &_btn {
      width: 20vw;
    }
  }
  .bg-title {
    background-size: 200% auto;
  }
  .nodata {
    font-size: 5vw;
    img {
      width: 30vw;
    }
  }
  .item {
    float: none;
    width: 100vw;
    height: 30vh;
    margin-bottom: 4vh;
  }
  .container {
    height: auto;
  }
  .header {
    height: 7vh;
  }
  .header-info-item .value {
    font-size: 3.4vw;
  }
  .list {
    height: calc(100% - 20vh);
    overflow: auto;
  }
}

#index {
  background-color: #020308;
}
</style>
