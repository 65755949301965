<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import tdTheme from './theme.json' // 引入默认主题
import chinaJson from './china.json'
import '../map/fujian.js'
// import echarts from 'echarts'

export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    options: {
      type: Object,
      default: ()=>({})
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      chart: null,
      timer: null
    }
  },
  watch: {
    options: {
      handler (options) {
        // 设置true清空echart缓存
        // this.chart.setOption(options, true)
        // console.log('watch', options)
        // if (this.type === 'bar') {
        //   this.flicker(options)
        // } else {
        //   this.chart && this.chart.setOption(options, true)
        // }
        this.chart && this.chart.setOption(options, true)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
    this.initChart();
  },
  beforeDestroy () {
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      this.$echarts.registerMap('china', chinaJson);
      this.chart.setOption(this.options, true)
    },
    flicker(options) {
      if (!options) return
      var count = 0;
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => {
        console.log('flicker', options.series)
          if(count%2 == 0){
            options.series = options.series.slice && options.series.slice(0, 1)
            this.chart.setOption(options, true);    //交替setOption
          }else{
            options.series.push(
                {
                  type: 'bar',
                  data: options.series[0].data,
                  barGap: '-100%',
                  zlevel: 2,
                  itemStyle: {
                      normal: {
                          color: {
                            x: 0,
                            y: 1,
                            colorStops: [
                              {
                                offset: 0,
                                color: 'rgba(255, 255, 255, 0)'
                              },
                              {
                                offset: 1,
                                color: 'rgba(255, 255, 255, 0.2)'
                              }
                            ]
                          }
                          // new echarts.graphic.LinearGradient(0, 1, [{
                          //     offset: 0,
                          //     color: 'rgba(255, 255, 255, 0)'
                          // }, {offset: 1, color: 'rgba(255, 255, 255, 0.3)'}])
                      }
                  }
                }
              )
              this.chart.setOption(options, true)
          }
          count+=1;
      },1000);
    }
  }
}
</script>

<style>
</style>
