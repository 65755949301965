export default {
  "totalTitle": "杭州 2022年",
  "rasaDto": {
    "text": "2022年杭州地铁总体客流情况",
    "intent": {
      "name": "flowtrend",
      "confidence": 1
    },
    "entities": [
      {
        "entity": "date",
        "start": 0,
        "end": 5,
        "confidence_entity": 0.9999997615814209,
        "value": "2022年",
        "extractor": "DIETClassifier",
        "role": "start"
      },
      {
        "entity": "item",
        "start": 11,
        "end": 13,
        "confidence_entity": 0.9273399710655212,
        "value": "客流",
        "extractor": "DIETClassifier"
      }
    ],
    "intent_ranking": [
      {
        "name": "flowtrend",
        "confidence": 1
      },
      {
        "name": "velocity",
        "confidence": 0
      },
      {
        "name": "equipreliab",
        "confidence": 0
      },
      {
        "name": "ODflow_l_from_s",
        "confidence": 0
      },
      {
        "name": "ODflow_l_to_l",
        "confidence": 0
      }
    ]
  },
  "itemQueryParam": {
    "intent": "总客流情况",
      "space": "线网",
        "time": "年",
          "item": "总客流情况",
            "ticket": "",
              "rasaIntent": "flowtrend",
                "businessIntent": "总客流情况",
                  "intentItem": "总客流情况",
                    "timeExpend": true,
                      "spaceExpend": true
  },
  "timeDetail": {
    "anchor": "2023-10-23 16:56:42"
  },
  "paramRaw": {
    "business_month": [
      "'2022-01'",
      "'2022-02'",
      "'2022-03'",
      "'2022-04'",
      "'2022-05'",
      "'2022-06'",
      "'2022-07'",
      "'2022-08'",
      "'2022-09'",
      "'2022-10'",
      "'2022-11'",
      "'2022-12'"
    ],
    "business_year": [
      "'2022'"
    ],
    "line_id": [
      "1",
      "16",
      "2",
      "22",
      "25",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9"
    ]
  },
  "queryDtos": [
    {
      "flowName": "客运量",
      "column": "CAPACITY",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 101,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": 96050.7371
        },
        "title": "线网年客运量",
        "type": "text",
        "unit": "万人次",
        "xname": "年",
        "yname": "客运量",
        "level": 101
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 1,
        "itemName": "客运量",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "客运量",
      "column": "CAPACITY",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 101,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                6456.4404,
                5864.3108,
                8021.5121,
                6472.0258,
                7256.5351,
                8689.8645,
                9503.3491,
                9453.7195,
                9393.6454,
                9289.7887,
                9238.3736,
                6411.1721
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月客运量",
        "type": "line",
        "unit": "万人次",
        "xname": "月",
        "yname": "客运量",
        "level": 101
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 1,
        "itemName": "客运量",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "客运量",
      "column": "CAPACITY",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 101,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                22068.7113,
                18547.6569,
                5641.9905,
                10262.6422,
                18656.6992,
                7811.1961,
                3960.9701,
                728.3348,
                4598.5837,
                1703.5358,
                1547.1424,
                796.522
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年客运量",
        "type": "bar",
        "unit": "万人次",
        "xname": "年",
        "yname": "客运量",
        "level": 101
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 1,
        "itemName": "客运量",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "进站客流",
      "column": "IN_COUNT",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 102,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": 59242.3187
        },
        "title": "线网年进站客流",
        "type": "text",
        "unit": "万人次",
        "xname": "年",
        "yname": "进站客流",
        "level": 102
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 2,
        "itemName": "进站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "进站客流",
      "column": "IN_COUNT",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 102,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                4135.1929,
                3713.7239,
                4984.9934,
                4062.9791,
                4506.3426,
                5329.5215,
                5795.5117,
                5758.5005,
                5726.7772,
                5659.6066,
                5624.8429,
                3944.3264
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月进站客流",
        "type": "line",
        "unit": "万人次",
        "xname": "月",
        "yname": "进站客流",
        "level": 102
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 2,
        "itemName": "进站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "进站客流",
      "column": "IN_COUNT",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 102,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                14163.2064,
                12317.6615,
                3391.1759,
                6102.7873,
                10669.5364,
                4920.3056,
                2385.221,
                379.9982,
                2658.5182,
                1015.7799,
                933.2209,
                477.5215
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年进站客流",
        "type": "bar",
        "unit": "万人次",
        "xname": "年",
        "yname": "进站客流",
        "level": 102
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 2,
        "itemName": "进站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "票种进站客流",
      "column": "IN_COUNT",
      "title": "线网票种年客流",
      "tableName": "IND_HZACC_TCCS_NET_TICKET_Y",
      "timeType": "1",
      "level": 103,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "ticketLabel": "TICKET_FAMILY_TYPE",
      "chartEntity": {
        "data": [
          {
            "name": "储值卡",
            "value": 15311.1197
          },
          {
            "name": "其他票种",
            "value": 0.0061
          },
          {
            "name": "二维码",
            "value": 39146.4129
          },
          {
            "name": "单程票",
            "value": 3419.721
          },
          {
            "name": "银联卡",
            "value": 1697.311
          }
        ],
        "title": "线网年票种进站客流",
        "type": "stereoscopicPie",
        "unit": "万人次",
        "xname": "年",
        "yname": "票种进站客流",
        "level": 103
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NET_TICKET_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 3,
        "itemName": "票种进站客流",
        "chartTypeSm": "stackedBar",
        "chartTypeSingle": "stereoscopicPie",
        "chartTypeLg": "stackedBar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "票种进站客流",
      "column": "IN_COUNT",
      "title": "线网票种月客流",
      "tableName": "IND_HZACC_TCCS_NET_TICKET_M",
      "timeType": "1",
      "level": 103,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "ticketLabel": "TICKET_FAMILY_TYPE",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "二维码",
              "data": [
                2648.6356,
                2428.2237,
                3403.9023,
                2646.5619,
                2929.495,
                3536.2158,
                3881.5766,
                3826.8253,
                3767.6064,
                3759.7649,
                3683.0578,
                2634.5476
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "储值卡",
              "data": [
                1125.8237,
                935.5197,
                1341.6135,
                1067.1435,
                1209.2094,
                1357.8449,
                1403.6962,
                1430.4932,
                1484.7942,
                1454.0565,
                1524.2373,
                976.6876
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "其他票种",
              "data": [
                0.0031,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                0.003,
                null,
                null,
                null
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "单程票",
              "data": [
                261.2081,
                242.3928,
                281.4447,
                211.6967,
                239.954,
                305.1182,
                374.9979,
                359.1289,
                320.4765,
                320.9179,
                281.8648,
                220.5205
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "银联卡",
              "data": [
                138.0844,
                115.2069,
                161.7777,
                133.1477,
                131.963,
                146.5461,
                141.4633,
                153.5438,
                161.5318,
                143.5095,
                156.8189,
                113.7179
              ],
              "type": "bar",
              "stack": "first"
            }
          ]
        },
        "title": "线网月票种进站客流",
        "type": "stackedBar",
        "unit": "万人次",
        "xname": "月",
        "yname": "票种进站客流",
        "level": 103
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NET_TICKET_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 3,
        "itemName": "票种进站客流",
        "chartTypeSm": "stackedBar",
        "chartTypeSingle": "stereoscopicPie",
        "chartTypeLg": "stackedBar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "票种进站客流",
      "column": "IN_COUNT",
      "title": "线路票种年客流",
      "tableName": "IND_HZACC_TCCS_LINE_TICKET_Y",
      "timeType": "1",
      "level": 103,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "ticketLabel": "TICKET_FAMILY_TYPE",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "二维码",
              "data": [
                10723.3137,
                8072.5744,
                2039.9768,
                3041.1589,
                7270.8751,
                3000.154,
                1525.8928,
                294.8242,
                1690.6254,
                680.3833,
                546.5547,
                260.3936
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "储值卡",
              "data": [
                3762.9262,
                3555.2833,
                949.4857,
                1312.6601,
                2941.3462,
                1040.4351,
                517.5978,
                81.5896,
                621.8224,
                229.3394,
                217.7349,
                81.1544
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "其他票种",
              "data": [
                0.0001,
                0.003,
                null,
                0.0031,
                0,
                0.0003,
                null,
                null,
                null,
                null,
                0.0001,
                null
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "单程票",
              "data": [
                1024.7748,
                449.6039,
                185.6431,
                189.0649,
                582.3293,
                277.5697,
                232.106,
                50.3803,
                221.5662,
                61.5961,
                130.4326,
                23.4716
              ],
              "type": "bar",
              "stack": "first"
            },
            {
              "name": "银联卡",
              "data": [
                439.6884,
                429.8955,
                65.5948,
                157.5129,
                321.4497,
                110.1736,
                53.5514,
                6.5696,
                64.7031,
                22.6362,
                17.1736,
                9.2598
              ],
              "type": "bar",
              "stack": "first"
            }
          ]
        },
        "title": "线路年票种进站客流",
        "type": "stackedBar",
        "unit": "万人次",
        "xname": "年",
        "yname": "票种进站客流",
        "level": 103
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_TICKET_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 3,
        "itemName": "票种进站客流",
        "chartTypeSm": "stackedBar",
        "chartTypeSingle": "stereoscopicPie",
        "chartTypeLg": "stackedBar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量",
      "column": "IN_COUNT_AVG",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": "162.7536,0.0"
        },
        "title": "线网年日均进站量",
        "type": "text",
        "unit": "万人次",
        "group": 1,
        "xname": "年",
        "yname": "日均进站量",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 4,
        "itemName": "日均进站量",
        "groupCode": 1,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量增长率",
      "column": "IN_COUNT_AVG_GROWTH_RATE",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": 0
        },
        "title": "线网年日均进站量增长率",
        "type": "text",
        "unit": "%",
        "group": 1,
        "xname": "年",
        "yname": "日均进站量增长率",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 5,
        "itemName": "日均进站量增长率",
        "groupCode": 1,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量",
      "column": "IN_COUNT_AVG",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                133.3933,
                132.633,
                166.1664,
                135.4326,
                145.3659,
                177.6507,
                186.952,
                185.7581,
                190.8926,
                182.568,
                187.4948,
                127.2363
              ],
              "type": "bar"
            },
            {
              "name": "",
              "data": [
                0,
                0.57,
                25.28,
                18.5,
                7.33,
                22.21,
                5.24,
                0.64,
                2.76,
                4.36,
                2.7,
                32.14
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月日均进站量",
        "type": "bar",
        "unit": "万人次",
        "group": 1,
        "xname": "月",
        "yname": "日均进站量",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 4,
        "itemName": "日均进站量",
        "groupCode": 1,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量增长率",
      "column": "IN_COUNT_AVG_GROWTH_RATE",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                0,
                0.57,
                25.28,
                18.5,
                7.33,
                22.21,
                5.24,
                0.64,
                2.76,
                4.36,
                2.7,
                32.14
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月日均进站量增长率",
        "type": "line",
        "unit": "%",
        "group": 1,
        "xname": "月",
        "yname": "日均进站量增长率",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 5,
        "itemName": "日均进站量增长率",
        "groupCode": 1,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量",
      "column": "IN_COUNT_AVG",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                38.8033,
                33.747,
                10.7999,
                16.7659,
                29.2316,
                13.4803,
                6.5349,
                1.0411,
                7.3036,
                3.235,
                2.5568,
                4.7279
              ],
              "type": "bar"
            },
            {
              "name": "2022",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年日均进站量",
        "type": "bar",
        "unit": "万人次",
        "group": 1,
        "xname": "年",
        "yname": "日均进站量",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 4,
        "itemName": "日均进站量",
        "groupCode": 1,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均进站量增长率",
      "column": "IN_COUNT_AVG_GROWTH_RATE",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 104,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年日均进站量增长率",
        "type": "bar",
        "unit": "%",
        "group": 1,
        "xname": "年",
        "yname": "日均进站量增长率",
        "level": 104
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 5,
        "itemName": "日均进站量增长率",
        "groupCode": 1,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量",
      "column": "CAPACITY_AVG",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": "263.8757,0.0"
        },
        "title": "线网年日均客运量",
        "type": "text",
        "unit": "万人次",
        "group": 2,
        "xname": "年",
        "yname": "日均客运量",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 6,
        "itemName": "日均客运量",
        "groupCode": 2,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量增长率",
      "column": "CAPACITY_AVG_GROWTH_RATE",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": 0
        },
        "title": "线网年日均客运量增长率",
        "type": "text",
        "unit": "%",
        "group": 2,
        "xname": "年",
        "yname": "日均客运量增长率",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 7,
        "itemName": "日均客运量增长率",
        "groupCode": 2,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量",
      "column": "CAPACITY_AVG",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                208.2723,
                209.4397,
                267.3837,
                215.7342,
                234.0818,
                289.6622,
                306.5596,
                304.9587,
                313.1215,
                299.6706,
                307.9458,
                206.812
              ],
              "type": "bar"
            },
            {
              "name": "",
              "data": [
                0,
                0.56,
                27.67,
                19.32,
                8.5,
                23.74,
                5.83,
                0.52,
                2.68,
                4.3,
                2.76,
                32.84
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月日均客运量",
        "type": "bar",
        "unit": "万人次",
        "group": 2,
        "xname": "月",
        "yname": "日均客运量",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 6,
        "itemName": "日均客运量",
        "groupCode": 2,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量增长率",
      "column": "CAPACITY_AVG_GROWTH_RATE",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                0,
                0.56,
                27.67,
                19.32,
                8.5,
                23.74,
                5.83,
                0.52,
                2.68,
                4.3,
                2.76,
                32.84
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月日均客运量增长率",
        "type": "line",
        "unit": "%",
        "group": 2,
        "xname": "月",
        "yname": "日均客运量增长率",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 7,
        "itemName": "日均客运量增长率",
        "groupCode": 2,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量",
      "column": "CAPACITY_AVG",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                60.4622,
                50.8155,
                17.9681,
                28.1941,
                51.1142,
                21.4005,
                10.852,
                1.9954,
                12.6335,
                5.4253,
                4.2387,
                7.8864
              ],
              "type": "bar"
            },
            {
              "name": "2022",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年日均客运量",
        "type": "bar",
        "unit": "万人次",
        "group": 2,
        "xname": "年",
        "yname": "日均客运量",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 6,
        "itemName": "日均客运量",
        "groupCode": 2,
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "bar",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "日均客运量增长率",
      "column": "CAPACITY_AVG_GROWTH_RATE",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 105,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年日均客运量增长率",
        "type": "bar",
        "unit": "%",
        "group": 2,
        "xname": "年",
        "yname": "日均客运量增长率",
        "level": 105
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 7,
        "itemName": "日均客运量增长率",
        "groupCode": 2,
        "chartTypeSm": "line",
        "chartTypeSingle": "",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "%",
        "sumFlag": 0,
        "dataType": "java.lang.Double",
        "dateFormatPattern": "",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "出站客流",
      "column": "OUT_COUNT",
      "title": "线网年客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 107,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "value": 59463.9219
        },
        "title": "线网年出站客流",
        "type": "text",
        "unit": "万人次",
        "xname": "年",
        "yname": "出站客流",
        "level": 107
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022')",
      "chartConfig": {
        "id": 9,
        "itemName": "出站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "出站客流",
      "column": "OUT_COUNT",
      "title": "线网月客流分析",
      "tableName": "IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M",
      "timeType": "1",
      "level": 107,
      "timeLabel": "BUSINESS_MONTH",
      "spaceLabel": "",
      "chartEntity": {
        "data": {
          "categories": [
            "2022-01",
            "2022-02",
            "2022-03",
            "2022-04",
            "2022-05",
            "2022-06",
            "2022-07",
            "2022-08",
            "2022-09",
            "2022-10",
            "2022-11",
            "2022-12"
          ],
          "series": [
            {
              "name": "",
              "data": [
                4151.4387,
                3724.374,
                4999.3725,
                4099.6454,
                4516.6923,
                5350.152,
                5808.3455,
                5778.4245,
                5735.0212,
                5691.596,
                5656.1273,
                3952.7325
              ],
              "type": "line"
            }
          ]
        },
        "title": "线网月出站客流",
        "type": "line",
        "unit": "万人次",
        "xname": "月",
        "yname": "出站客流",
        "level": 107
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_NETWORK_FLOW_ANALYSIS_M   where 1=1   and business_month in('2022-01','2022-02','2022-03','2022-04','2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11','2022-12')",
      "chartConfig": {
        "id": 9,
        "itemName": "出站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "doubleFixedNum": 4
      }
    },
    {
      "flowName": "出站客流",
      "column": "OUT_COUNT",
      "title": "线路年客流分析",
      "tableName": "IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y",
      "timeType": "1",
      "level": 107,
      "timeLabel": "BUSINESS_YEAR",
      "spaceLabel": "LINE_ID",
      "chartEntity": {
        "data": {
          "categories": [
            "1号线",
            "2号线",
            "3号线",
            "4号线",
            "5号线",
            "6号线",
            "7号线",
            "8号线",
            "9号线",
            "10号线",
            "16号线",
            "19号线"
          ],
          "series": [
            {
              "name": "2022",
              "data": [
                13730.3615,
                12136.6315,
                3434.0852,
                6051.1319,
                10946.2585,
                5219.6607,
                2536.9588,
                427.5871,
                2712.2686,
                1022.4842,
                938.237,
                479.7527
              ],
              "type": "bar"
            }
          ]
        },
        "title": "线路年出站客流",
        "type": "bar",
        "unit": "万人次",
        "xname": "年",
        "yname": "出站客流",
        "level": 107
      },
      "sql": "SELECT * from dm.IND_HZACC_TCCS_LINE_FLOW_ANALYSIS_Y   where 1=1   and business_year in('2022') and line_id in(1,16,2,22,25,3,4,5,6,7,8,9)",
      "chartConfig": {
        "id": 9,
        "itemName": "出站客流",
        "chartTypeSm": "bar",
        "chartTypeSingle": "text",
        "chartTypeLg": "line",
        "divideN": "6,50,6,180",
        "unit": "万人次",
        "sumFlag": 1,
        "dataType": "java.lang.Double",
        "doubleFixedNum": 4
      }
    }
  ],
  "chartEntities": [
    {
      "data": {
        "value": 96050.7371
      },
      "title": "线网年客运量",
      "type": "text",
      "unit": "万人次",
      "xname": "年",
      "yname": "客运量",
      "level": 101
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "",
            "data": [
              6456.4404,
              5864.3108,
              8021.5121,
              6472.0258,
              7256.5351,
              8689.8645,
              9503.3491,
              9453.7195,
              9393.6454,
              9289.7887,
              9238.3736,
              6411.1721
            ],
            "type": "line"
          }
        ]
      },
      "title": "线网月客运量",
      "type": "line",
      "unit": "万人次",
      "xname": "月",
      "yname": "客运量",
      "level": 101
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "2022",
            "data": [
              22068.7113,
              18547.6569,
              5641.9905,
              10262.6422,
              18656.6992,
              7811.1961,
              3960.9701,
              728.3348,
              4598.5837,
              1703.5358,
              1547.1424,
              796.522
            ],
            "type": "bar"
          }
        ]
      },
      "title": "线路年客运量",
      "type": "bar",
      "unit": "万人次",
      "xname": "年",
      "yname": "客运量",
      "level": 101
    },
    {
      "data": {
        "value": 59242.3187
      },
      "title": "线网年进站客流",
      "type": "text",
      "unit": "万人次",
      "xname": "年",
      "yname": "进站客流",
      "level": 102
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "",
            "data": [
              4135.1929,
              3713.7239,
              4984.9934,
              4062.9791,
              4506.3426,
              5329.5215,
              5795.5117,
              5758.5005,
              5726.7772,
              5659.6066,
              5624.8429,
              3944.3264
            ],
            "type": "line"
          }
        ]
      },
      "title": "线网月进站客流",
      "type": "line",
      "unit": "万人次",
      "xname": "月",
      "yname": "进站客流",
      "level": 102
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "2022",
            "data": [
              14163.2064,
              12317.6615,
              3391.1759,
              6102.7873,
              10669.5364,
              4920.3056,
              2385.221,
              379.9982,
              2658.5182,
              1015.7799,
              933.2209,
              477.5215
            ],
            "type": "bar"
          }
        ]
      },
      "title": "线路年进站客流",
      "type": "bar",
      "unit": "万人次",
      "xname": "年",
      "yname": "进站客流",
      "level": 102
    },
    {
      "data": [
        {
          "name": "储值卡",
          "value": 15311.1197
        },
        {
          "name": "其他票种",
          "value": 0.0061
        },
        {
          "name": "二维码",
          "value": 39146.4129
        },
        {
          "name": "单程票",
          "value": 3419.721
        },
        {
          "name": "银联卡",
          "value": 1697.311
        }
      ],
      "title": "线网年票种进站客流",
      "type": "stereoscopicPie",
      "unit": "万人次",
      "xname": "年",
      "yname": "票种进站客流",
      "level": 103
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "二维码",
            "data": [
              2648.6356,
              2428.2237,
              3403.9023,
              2646.5619,
              2929.495,
              3536.2158,
              3881.5766,
              3826.8253,
              3767.6064,
              3759.7649,
              3683.0578,
              2634.5476
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "储值卡",
            "data": [
              1125.8237,
              935.5197,
              1341.6135,
              1067.1435,
              1209.2094,
              1357.8449,
              1403.6962,
              1430.4932,
              1484.7942,
              1454.0565,
              1524.2373,
              976.6876
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "其他票种",
            "data": [
              0.0031,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.003,
              null,
              null,
              null
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "单程票",
            "data": [
              261.2081,
              242.3928,
              281.4447,
              211.6967,
              239.954,
              305.1182,
              374.9979,
              359.1289,
              320.4765,
              320.9179,
              281.8648,
              220.5205
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "银联卡",
            "data": [
              138.0844,
              115.2069,
              161.7777,
              133.1477,
              131.963,
              146.5461,
              141.4633,
              153.5438,
              161.5318,
              143.5095,
              156.8189,
              113.7179
            ],
            "type": "bar",
            "stack": "first"
          }
        ]
      },
      "title": "线网月票种进站客流",
      "type": "stackedBar",
      "unit": "万人次",
      "xname": "月",
      "yname": "票种进站客流",
      "level": 103
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "二维码",
            "data": [
              10723.3137,
              8072.5744,
              2039.9768,
              3041.1589,
              7270.8751,
              3000.154,
              1525.8928,
              294.8242,
              1690.6254,
              680.3833,
              546.5547,
              260.3936
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "储值卡",
            "data": [
              3762.9262,
              3555.2833,
              949.4857,
              1312.6601,
              2941.3462,
              1040.4351,
              517.5978,
              81.5896,
              621.8224,
              229.3394,
              217.7349,
              81.1544
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "其他票种",
            "data": [
              0.0001,
              0.003,
              null,
              0.0031,
              0,
              0.0003,
              null,
              null,
              null,
              null,
              0.0001,
              null
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "单程票",
            "data": [
              1024.7748,
              449.6039,
              185.6431,
              189.0649,
              582.3293,
              277.5697,
              232.106,
              50.3803,
              221.5662,
              61.5961,
              130.4326,
              23.4716
            ],
            "type": "bar",
            "stack": "first"
          },
          {
            "name": "银联卡",
            "data": [
              439.6884,
              429.8955,
              65.5948,
              157.5129,
              321.4497,
              110.1736,
              53.5514,
              6.5696,
              64.7031,
              22.6362,
              17.1736,
              9.2598
            ],
            "type": "bar",
            "stack": "first"
          }
        ]
      },
      "title": "线路年票种进站客流",
      "type": "stackedBar",
      "unit": "万人次",
      "xname": "年",
      "yname": "票种进站客流",
      "level": 103
    },
    {
      "data": {
        "value": "162.7536,0.0"
      },
      "title": "线网年日均进站量,增长率",
      "type": "text",
      "level": 104,
      "sub_type": ""
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "",
            "data": [
              133.3933,
              132.633,
              166.1664,
              135.4326,
              145.3659,
              177.6507,
              186.952,
              185.7581,
              190.8926,
              182.568,
              187.4948,
              127.2363
            ],
            "type": "bar"
          },
          {
            "name": "",
            "data": [
              0,
              0.57,
              25.28,
              18.5,
              7.33,
              22.21,
              5.24,
              0.64,
              2.76,
              4.36,
              2.7,
              32.14
            ],
            "type": "line"
          }
        ]
      },
      "title": "线网月日均进站量,增长率",
      "type": "lineBar",
      "level": 104,
      "sub_type": ""
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "2022",
            "data": [
              38.8033,
              33.747,
              10.7999,
              16.7659,
              29.2316,
              13.4803,
              6.5349,
              1.0411,
              7.3036,
              3.235,
              2.5568,
              4.7279
            ],
            "type": "bar"
          },
          {
            "name": "2022",
            "data": [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ],
            "type": "bar"
          }
        ]
      },
      "title": "线路年日均进站量,增长率",
      "type": "lineBar",
      "level": 104,
      "sub_type": ""
    },
    {
      "data": {
        "value": "263.8757,0.0"
      },
      "title": "线网年日均客运量,增长率",
      "type": "text",
      "level": 105,
      "sub_type": ""
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "",
            "data": [
              208.2723,
              209.4397,
              267.3837,
              215.7342,
              234.0818,
              289.6622,
              306.5596,
              304.9587,
              313.1215,
              299.6706,
              307.9458,
              206.812
            ],
            "type": "bar"
          },
          {
            "name": "",
            "data": [
              0,
              0.56,
              27.67,
              19.32,
              8.5,
              23.74,
              5.83,
              0.52,
              2.68,
              4.3,
              2.76,
              32.84
            ],
            "type": "line"
          }
        ]
      },
      "title": "线网月日均客运量,增长率",
      "type": "lineBar",
      "level": 105,
      "sub_type": ""
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "2022",
            "data": [
              60.4622,
              50.8155,
              17.9681,
              28.1941,
              51.1142,
              21.4005,
              10.852,
              1.9954,
              12.6335,
              5.4253,
              4.2387,
              7.8864
            ],
            "type": "bar"
          },
          {
            "name": "2022",
            "data": [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ],
            "type": "bar"
          }
        ]
      },
      "title": "线路年日均客运量,增长率",
      "type": "lineBar",
      "level": 105,
      "sub_type": ""
    },
    {
      "data": {
        "value": 59463.9219
      },
      "title": "线网年出站客流",
      "type": "text",
      "unit": "万人次",
      "xname": "年",
      "yname": "出站客流",
      "level": 107
    },
    {
      "data": {
        "categories": [
          "2022-01",
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12"
        ],
        "series": [
          {
            "name": "",
            "data": [
              4151.4387,
              3724.374,
              4999.3725,
              4099.6454,
              4516.6923,
              5350.152,
              5808.3455,
              5778.4245,
              5735.0212,
              5691.596,
              5656.1273,
              3952.7325
            ],
            "type": "line"
          }
        ]
      },
      "title": "线网月出站客流",
      "type": "line",
      "unit": "万人次",
      "xname": "月",
      "yname": "出站客流",
      "level": 107
    },
    {
      "data": {
        "categories": [
          "1号线",
          "2号线",
          "3号线",
          "4号线",
          "5号线",
          "6号线",
          "7号线",
          "8号线",
          "9号线",
          "10号线",
          "16号线",
          "19号线"
        ],
        "series": [
          {
            "name": "2022",
            "data": [
              13730.3615,
              12136.6315,
              3434.0852,
              6051.1319,
              10946.2585,
              5219.6607,
              2536.9588,
              427.5871,
              2712.2686,
              1022.4842,
              938.237,
              479.7527
            ],
            "type": "bar"
          }
        ]
      },
      "title": "线路年出站客流",
      "type": "bar",
      "unit": "万人次",
      "xname": "年",
      "yname": "出站客流",
      "level": 107
    }
  ],
  "duringTitle": "",
  "answerDto": {
    "text": "您好，为你找到以下内容",
      "chart_list": [
        {
          "priority": 0,
          "group": 101,
          "chart_title": "线网年客运量",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "客运量",
          "data": {
            "value": 96050.7371
          },
          "type": "text"
        },
        {
          "priority": 1,
          "group": 101,
          "chart_title": "线网月客运量",
          "unit": "万人次",
          "x_name": "月",
          "y_name": "客运量",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "",
                "data": [
                  6456.4404,
                  5864.3108,
                  8021.5121,
                  6472.0258,
                  7256.5351,
                  8689.8645,
                  9503.3491,
                  9453.7195,
                  9393.6454,
                  9289.7887,
                  9238.3736,
                  6411.1721
                ],
                "type": "line"
              }
            ]
          },
          "type": "line"
        },
        {
          "priority": 2,
          "group": 101,
          "chart_title": "线路年客运量",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "客运量",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "2022",
                "data": [
                  22068.7113,
                  18547.6569,
                  5641.9905,
                  10262.6422,
                  18656.6992,
                  7811.1961,
                  3960.9701,
                  728.3348,
                  4598.5837,
                  1703.5358,
                  1547.1424,
                  796.522
                ],
                "type": "bar"
              }
            ]
          },
          "type": "bar"
        },
        {
          "priority": 3,
          "group": 102,
          "chart_title": "线网年进站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "进站客流",
          "data": {
            "value": 59242.3187
          },
          "type": "text"
        },
        {
          "priority": 4,
          "group": 102,
          "chart_title": "线网月进站客流",
          "unit": "万人次",
          "x_name": "月",
          "y_name": "进站客流",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "",
                "data": [
                  4135.1929,
                  3713.7239,
                  4984.9934,
                  4062.9791,
                  4506.3426,
                  5329.5215,
                  5795.5117,
                  5758.5005,
                  5726.7772,
                  5659.6066,
                  5624.8429,
                  3944.3264
                ],
                "type": "line"
              }
            ]
          },
          "type": "line"
        },
        {
          "priority": 5,
          "group": 102,
          "chart_title": "线路年进站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "进站客流",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "2022",
                "data": [
                  14163.2064,
                  12317.6615,
                  3391.1759,
                  6102.7873,
                  10669.5364,
                  4920.3056,
                  2385.221,
                  379.9982,
                  2658.5182,
                  1015.7799,
                  933.2209,
                  477.5215
                ],
                "type": "bar"
              }
            ]
          },
          "type": "bar"
        },
        {
          "priority": 6,
          "group": 103,
          "chart_title": "线网年票种进站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "票种进站客流",
          "data": [
            {
              "name": "储值卡",
              "value": 15311.1197
            },
            {
              "name": "其他票种",
              "value": 0.0061
            },
            {
              "name": "二维码",
              "value": 39146.4129
            },
            {
              "name": "单程票",
              "value": 3419.721
            },
            {
              "name": "银联卡",
              "value": 1697.311
            }
          ],
          "type": "stereoscopicPie"
        },
        {
          "priority": 7,
          "group": 103,
          "chart_title": "线网月票种进站客流",
          "unit": "万人次",
          "x_name": "月",
          "y_name": "票种进站客流",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "二维码",
                "data": [
                  2648.6356,
                  2428.2237,
                  3403.9023,
                  2646.5619,
                  2929.495,
                  3536.2158,
                  3881.5766,
                  3826.8253,
                  3767.6064,
                  3759.7649,
                  3683.0578,
                  2634.5476
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "储值卡",
                "data": [
                  1125.8237,
                  935.5197,
                  1341.6135,
                  1067.1435,
                  1209.2094,
                  1357.8449,
                  1403.6962,
                  1430.4932,
                  1484.7942,
                  1454.0565,
                  1524.2373,
                  976.6876
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "其他票种",
                "data": [
                  0.0031,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  0.003,
                  null,
                  null,
                  null
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "单程票",
                "data": [
                  261.2081,
                  242.3928,
                  281.4447,
                  211.6967,
                  239.954,
                  305.1182,
                  374.9979,
                  359.1289,
                  320.4765,
                  320.9179,
                  281.8648,
                  220.5205
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "银联卡",
                "data": [
                  138.0844,
                  115.2069,
                  161.7777,
                  133.1477,
                  131.963,
                  146.5461,
                  141.4633,
                  153.5438,
                  161.5318,
                  143.5095,
                  156.8189,
                  113.7179
                ],
                "type": "bar",
                "stack": "first"
              }
            ]
          },
          "type": "stackedBar"
        },
        {
          "priority": 8,
          "group": 103,
          "chart_title": "线路年票种进站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "票种进站客流",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "二维码",
                "data": [
                  10723.3137,
                  8072.5744,
                  2039.9768,
                  3041.1589,
                  7270.8751,
                  3000.154,
                  1525.8928,
                  294.8242,
                  1690.6254,
                  680.3833,
                  546.5547,
                  260.3936
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "储值卡",
                "data": [
                  3762.9262,
                  3555.2833,
                  949.4857,
                  1312.6601,
                  2941.3462,
                  1040.4351,
                  517.5978,
                  81.5896,
                  621.8224,
                  229.3394,
                  217.7349,
                  81.1544
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "其他票种",
                "data": [
                  0.0001,
                  0.003,
                  null,
                  0.0031,
                  0,
                  0.0003,
                  null,
                  null,
                  null,
                  null,
                  0.0001,
                  null
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "单程票",
                "data": [
                  1024.7748,
                  449.6039,
                  185.6431,
                  189.0649,
                  582.3293,
                  277.5697,
                  232.106,
                  50.3803,
                  221.5662,
                  61.5961,
                  130.4326,
                  23.4716
                ],
                "type": "bar",
                "stack": "first"
              },
              {
                "name": "银联卡",
                "data": [
                  439.6884,
                  429.8955,
                  65.5948,
                  157.5129,
                  321.4497,
                  110.1736,
                  53.5514,
                  6.5696,
                  64.7031,
                  22.6362,
                  17.1736,
                  9.2598
                ],
                "type": "bar",
                "stack": "first"
              }
            ]
          },
          "type": "stackedBar"
        },
        {
          "priority": 9,
          "group": 104,
          "chart_title": "线网年日均进站量,增长率",
          "data": {
            "value": "162.7536,0.0"
          },
          "type": "text",
          "sub_type": ""
        },
        {
          "priority": 10,
          "group": 104,
          "chart_title": "线网月日均进站量,增长率",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "",
                "data": [
                  133.3933,
                  132.633,
                  166.1664,
                  135.4326,
                  145.3659,
                  177.6507,
                  186.952,
                  185.7581,
                  190.8926,
                  182.568,
                  187.4948,
                  127.2363
                ],
                "type": "bar"
              },
              {
                "name": "",
                "data": [
                  0,
                  0.57,
                  25.28,
                  18.5,
                  7.33,
                  22.21,
                  5.24,
                  0.64,
                  2.76,
                  4.36,
                  2.7,
                  32.14
                ],
                "type": "line"
              }
            ]
          },
          "type": "lineBar",
          "sub_type": ""
        },
        {
          "priority": 11,
          "group": 104,
          "chart_title": "线路年日均进站量,增长率",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "2022",
                "data": [
                  38.8033,
                  33.747,
                  10.7999,
                  16.7659,
                  29.2316,
                  13.4803,
                  6.5349,
                  1.0411,
                  7.3036,
                  3.235,
                  2.5568,
                  4.7279
                ],
                "type": "bar"
              },
              {
                "name": "2022",
                "data": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ],
                "type": "bar"
              }
            ]
          },
          "type": "lineBar",
          "sub_type": ""
        },
        {
          "priority": 12,
          "group": 105,
          "chart_title": "线网年日均客运量,增长率",
          "data": {
            "value": "263.8757,0.0"
          },
          "type": "text",
          "sub_type": ""
        },
        {
          "priority": 13,
          "group": 105,
          "chart_title": "线网月日均客运量,增长率",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "",
                "data": [
                  208.2723,
                  209.4397,
                  267.3837,
                  215.7342,
                  234.0818,
                  289.6622,
                  306.5596,
                  304.9587,
                  313.1215,
                  299.6706,
                  307.9458,
                  206.812
                ],
                "type": "bar"
              },
              {
                "name": "",
                "data": [
                  0,
                  0.56,
                  27.67,
                  19.32,
                  8.5,
                  23.74,
                  5.83,
                  0.52,
                  2.68,
                  4.3,
                  2.76,
                  32.84
                ],
                "type": "line"
              }
            ]
          },
          "type": "lineBar",
          "sub_type": ""
        },
        {
          "priority": 14,
          "group": 105,
          "chart_title": "线路年日均客运量,增长率",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "2022",
                "data": [
                  60.4622,
                  50.8155,
                  17.9681,
                  28.1941,
                  51.1142,
                  21.4005,
                  10.852,
                  1.9954,
                  12.6335,
                  5.4253,
                  4.2387,
                  7.8864
                ],
                "type": "bar"
              },
              {
                "name": "2022",
                "data": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ],
                "type": "bar"
              }
            ]
          },
          "type": "lineBar",
          "sub_type": ""
        },
        {
          "priority": 15,
          "group": 107,
          "chart_title": "线网年出站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "出站客流",
          "data": {
            "value": 59463.9219
          },
          "type": "text"
        },
        {
          "priority": 16,
          "group": 107,
          "chart_title": "线网月出站客流",
          "unit": "万人次",
          "x_name": "月",
          "y_name": "出站客流",
          "data": {
            "categories": [
              "2022-01",
              "2022-02",
              "2022-03",
              "2022-04",
              "2022-05",
              "2022-06",
              "2022-07",
              "2022-08",
              "2022-09",
              "2022-10",
              "2022-11",
              "2022-12"
            ],
            "series": [
              {
                "name": "",
                "data": [
                  4151.4387,
                  3724.374,
                  4999.3725,
                  4099.6454,
                  4516.6923,
                  5350.152,
                  5808.3455,
                  5778.4245,
                  5735.0212,
                  5691.596,
                  5656.1273,
                  3952.7325
                ],
                "type": "line"
              }
            ]
          },
          "type": "line"
        },
        {
          "priority": 17,
          "group": 107,
          "chart_title": "线路年出站客流",
          "unit": "万人次",
          "x_name": "年",
          "y_name": "出站客流",
          "data": {
            "categories": [
              "1号线",
              "2号线",
              "3号线",
              "4号线",
              "5号线",
              "6号线",
              "7号线",
              "8号线",
              "9号线",
              "10号线",
              "16号线",
              "19号线"
            ],
            "series": [
              {
                "name": "2022",
                "data": [
                  13730.3615,
                  12136.6315,
                  3434.0852,
                  6051.1319,
                  10946.2585,
                  5219.6607,
                  2536.9588,
                  427.5871,
                  2712.2686,
                  1022.4842,
                  938.237,
                  479.7527
                ],
                "type": "bar"
              }
            ]
          },
          "type": "bar"
        }
      ],
        "commond_type": "draw"
  },
  "expandLevel": "business_month"
}