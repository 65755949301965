const LAYOUT_1 = [{
  w: 10,
  h: 6
}]

const LAYOUT_1_MAP = [{
  w: 10,
  h: 6,
  map: true
}]

const LAYOUT_2 = [{
  w: 5,
  h: 6
},{
  w: 5,
  h: 6
}]

const LAYOUT_2_MAP = [{
  w: 5,
  h: 6,
  map: true
},{
  w: 5,
  h: 6
}]

const LAYOUT_3 = [{
  w: 5,
  h: 3
},{
  w: 5,
  h: 3
},{
  w: 10,
  h: 3
}]

const LAYOUT_3_MAP = [{
  w: 10,
  h: 4,
  map: true
},{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
}]

const LAYOUT_4 = [{
  w: 5,
  h: 3
},{
  w: 5,
  h: 3
},{
  w: 5,
  h: 3
},{
  w: 5,
  h: 3
}]

const LAYOUT_4_MAP = [{
  w: 10,
  h: 4,
  map: true
},{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
}]

const LAYOUT_5 = [{
  w: 3,
  h: 3
},{
  w: 4,
  h: 3
},{
  w: 3,
  h: 3
},{
  w: 5,
  h: 3
},{
  w: 5,
  h: 3
}]

const LAYOUT_5_MAP = [{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 3
  },{
    w: 2,
    h: 3
  }]
},{
  w: 6,
  h: 6,
  map: true
},{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 3
  },{
    w: 2,
    h: 3
  }]
}]

const LAYOUT_6 = [{
  w: 3,
  h: 3
},{
  w: 4,
  h: 3
},{
  w: 3,
  h: 3
},{
  w: 3,
  h: 3
},{
  w: 4,
  h: 3
},{
  w: 3,
  h: 3
}]

const LAYOUT_6_MAP = [{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
},{
  w: 6,
  h: 6,
  map: true
},{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 3
  },{
    w: 2,
    h: 3
  }]
}]

const LAYOUT_7 = [{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
}]

const LAYOUT_7_MAP = [{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
},{
  w: 6,
  h: 6,
  map: true
},{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
}]

const LAYOUT_8 = [{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
},
{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
}]

const LAYOUT_8_MAP = [{
  container: true,
  w: 3,
  h: 6,
  data: [
  {
    w: 3,
    h: 2
  },{
    w: 3,
    h: 2
  },{
    w: 3,
    h: 2
  }]
},{
  w: 4,
  h: 6,
  map: true,
  container: true,
  data: [
    {
      w: 4,
      h: 4,
      map: true,
    },
    {
      w: 4,
      h: 2
    },
  ]
},{
  container: true,
  w: 3,
  h: 6,
  data: [
  {
    w: 3,
    h: 2
  },{
    w: 3,
    h: 2
  },{
    w: 3,
    h: 2
  }]
}]

const LAYOUT_9 = [{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 4,
  h: 2
},{
  w: 3,
  h: 2
}]

const LAYOUT_9_MAP = [{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
},{
  w: 6,
  h: 6,
  map: true,
  container: true,
  data: [
    {
      w: 6,
      h: 4,
      map: true,
    },
    {
      w: 3,
      h: 2
    },
    {
      w: 3,
      h: 2
    }
  ]
},{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
}]

const LAYOUT_10 = [{
  w: 5,
  h: 2
},{
  w: 5,
  h: 2
},{
  w: 2,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 2,
  h: 2
},{
  w: 2,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 3,
  h: 2
},{
  w: 2,
  h: 2
}]

const LAYOUT_10_MAP = [{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
},{
  w: 6,
  h: 6,
  map: true,
  container: true,
  data: [
    {
      w: 6,
      h: 4,
      map: true,
    },
    {
      w: 2,
      h: 2
    },
    {
      w: 2,
      h: 2
    },
    {
      w: 2,
      h: 2
    }
  ]
},{
  container: true,
  w: 2,
  h: 6,
  data: [
  {
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  },{
    w: 2,
    h: 2
  }]
}]

export default {
  LAYOUT_1,
  LAYOUT_2,
  LAYOUT_3,
  LAYOUT_4,
  LAYOUT_5,
  LAYOUT_6,
  LAYOUT_7,
  LAYOUT_8,
  LAYOUT_9,
  LAYOUT_10,
  LAYOUT_1_MAP,
  LAYOUT_2_MAP,
  LAYOUT_3_MAP,
  LAYOUT_4_MAP,
  LAYOUT_5_MAP,
  LAYOUT_6_MAP,
  LAYOUT_7_MAP,
  LAYOUT_8_MAP,
  LAYOUT_9_MAP,
  LAYOUT_10_MAP
}