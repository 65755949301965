<template>
  <div :class="['item', isMap && 'map']" style="width: 100%;height: 100%;">
    <div v-if="data.type !== 'heatMap'" class="item-title"><span class="text">{{ data.chart_title }}</span></div>
    <!-- <dv-border-box-12> -->
      <Map v-if="data.type === 'heatMap'" :id="'bottomLeftChart' + index" :data="data" :isPhone="isPhone"></Map>
      <List v-else-if="data.type === 'list'" :list="data.data" @confirm="(item) => $emit('listClick', item)"></List>
      <div v-else-if="data.type === 'table'" class="table" >
        <el-table
        :data="data.data.data"
        style="width: 100%"
        stripe
        size="mini"
        >
        <el-table-column
          v-for="col, index in data.data.column"
          :prop="col.prop"
          :label="col.label"
          :key="col.prop + index"
        >
        </el-table-column>
      </el-table>
      </div>
      <Echart
        v-else-if="data.type !== 'stereoscopicPie' && data.type !== 'pie'"
        :options="{ 
          title: { show: false, text: data.chart_title, right: '10%', top: '0%', textStyle: { color: '#ffffff90', fontSize: 14 } }, 
          grid: { left: '25%', top: '10%', right: '10%', bottom: '18%' }, 
          xAxis: { name: data.x_name || '', data: data.data.categories, nameTextStyle: { fontSize: 10 } }, 
          yAxis: { name: data.unit, max: 'dataMax', TextStyle: { color: '#000' }, nameTextStyle: { fontSize: 10} }, 
          series: data.data.series,
          legend: { data: data.data.series, show: data.data.series?.length > 1, bottom: '0%', itemWidth: 10, itemHeight: 10, textStyle: { fontSize: 10 } },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
        }"
        :id="'bottomLeftChart' + index"
        height="calc(100% - 20px)"
        width="100%"
        :type="data.type"
      ></Echart>
      <Echart
        v-else
        :options="{ 
          title: { show: false, text: data.chart_title, right: '5%', top: '0%', textStyle: { color: '#ffffff90', fontSize: 14 } }, 
          grid: { left: '15%', top: '10%', right: '10%', bottom: '10%' },
          series: { type: 'pie', data: data.data, radius: ['40%', '70%'], itemStyle: { normal: { shadowColor: '#ffffff50',  shadowBlur: 20, } } },
          legend: { data: data.data, show: data.data?.length > 1, bottom: '0%', itemWidth: 10, itemHeight: 10, textStyle: { fontSize: 10 } },
          tooltip: {
            trigger: 'item'
          }
        }"
        :id="'bottomLeftChart' + index"
        height="calc(100% - 20px)"
        width="100%"
      ></Echart>
    <!-- </dv-border-box-12> -->
  </div>
</template>
<script>
import Echart from '@/common/echart'
import Map from './map.vue'
import List from './List.vue'

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: [Object, []],
      default: () => ({})
    },
    isMap: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Echart, Map, List
  },
  methods: {
    formatterX(val, index) {
      let remainder = index % 3;
      if (remainder == 0) {
          return val;
      }
      else{
          return "";
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-title {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding-left: 20px;
  position: relative;
  .text {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: '';
    position: absolute;
    width: 94%;
    height: 10px;
    background: linear-gradient(to right,#244f7990, #244f7910);
    z-index: 1;
    left: 10px;
    bottom: -3px;
    transform: skew(-20deg);
    border-bottom: 1px solid #244f7990;
  }
}
.map ::v-deep .dv-border-svg-container {
  display: none;
}

.table {
  width: 90%;
  height: 90%;
  margin: 0 auto;
  padding-top: 2vh;
  .title {
    margin-bottom: 10px;
    text-align: center;
  }
  
}

::v-deep .el-table {
  height: calc(100% - 2vw);
  background: none;
  &::before {
    display: none;
  }
  .el-table__body-wrapper {
    overflow: auto;
    height: calc(100% - 48px);
  }
  tr, th.el-table__cell  {
    // background: #1e2f60;
    background: none;
  }
  .thead, .el-table__row {
    color: #fff;
    &:hover {
      color: #000;
    }
  }
  &.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: rgba(29,85,139,.4);
    border-bottom-color: #ffffff50;
  }

  &.el-table--striped td.el-table__cell {
    border-bottom-color: #ffffff50;
  }

  &.el-table--striped th.el-table__cell {
    background: rgba(29,85,139, 0.8);
    border-bottom-color: #ffffff50;
  }
}
.item:not(.map) {
  // background-color: rgba(6, 30, 93, 0.5);
}
</style>