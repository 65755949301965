<template>
  <div :class="['audio', show && 'show']">
    <div class="audio-title">请说出您想要搜索的内容</div>
    <div class="audio-dur">
      <dv-decoration-6 :style="{width: isPhone ? '50vw' : '300px', height: '30px'}" />
      <span class="dur">{{ recorder && recorder.duration.toFixed(2) + 's' }}</span>
      <i class="stop" @click="handleStop"></i>
    </div>
    <br />
    <!-- <el-button type="primary" @click="handleStart">开始录音</el-button> -->
    <!-- <el-button type="warning" @click="handleStop">停止录音</el-button> -->
  </div>
</template>
<script>
import Recorder from 'js-audio-recorder'
import getToken from '@/utils/getToken'

export default {
  props: {
    isPhone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      show: false
    }
  },
  created() {
    this.recorder = new Recorder({
      type:"wav",
      sampleRate: 16000,
      sampleBits: 16,                // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
      numChannels: 1,
    })
  },
  watch: {
    recorder: {
      deep: true,
      handler(newData) {
        if (newData.duration >= 30) {
          this.handleStop()
        }
      }
    }
  },
  methods: {
    // 开始录音
    handleStart() {
      Recorder.getPermission().then(() => {
        console.log('开始录音')
        this.show = true
        this.recorder.start() // 开始录音
      }, (error) => {
        this.$message({
          message: '请先允许该网页使用麦克风',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },
    handleStop() {
      console.log('停止录音')
      this.recorder.stop() // 停止录音
      this.uploadRecord()
    },
    uploadRecord() {
      if (this.recorder == null || this.recorder.duration === 0) {
        this.$message({
          message: '请先录音',
          type: 'error'
        })
        return false
      }
      this.recorder.pause() // 暂停录音
      this.timer = null
      const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
      const fileOfBlob = new File([blob], 'test.wav', {type: 'audio/wav'})
      const formData = new FormData()
      const token = getToken()
      console.log(`output->token`,token)
      formData.append('multipartFile', fileOfBlob)
      formData.append('token', token)
      this.show = false
      this.$emit('upload', formData)
    },
  }
}

</script>
<style lang="scss" scoped>
  button {
    background: #222f5d;
  }
  .audio {
    position: absolute;
    bottom: 0;
    left: 0%;
    transform: translate(0, calc(100% + 50px));
    z-index: 99;
    color: #fff;
    padding: 10px 20px;
    background: #222f5d;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-out;
    &::before {
      content: '';
      position: absolute;
      top: -7px;
      left: 12px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;  
      border-right: 5px solid transparent;  
      border-bottom: 10px solid #222f5d;
    }
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translate(0, calc(100% + 10px));
    }
    &-title {
      text-align: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
    }
    &-dur {
      display: flex;
      align-items: center;
      .dur {
        width: 70px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      .stop {
        width: 40px;
        height: 40px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 15px;
          height: 15px;
          transform: translate(-50%, -50%);
          background: red;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
</style>